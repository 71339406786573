import request from '@/utils/request'

export function getInfo(query) {
  return request({
    url: '/product/info',
    method: 'get',
    params:query,
  })
}
export function getList(query) {
  return request({
    url: '/product/list',
    method: 'get',
    params:query,
  })
}
export function getTree(query) {
  return request({
    url: '/product-cate/tree',
    method: 'get',
    params:query,
  })
}





