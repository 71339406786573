<template>
  <div class="product-components p-t-40">
    <div class="bc-white p-t-20 ">
      <div class="wrap nav-wrap">
        <!-- <el-tabs class="nav" v-model="activeNameA" @tab-click="handleClick">
          <el-tab-pane label="产品类别:" lable="type" class="spa"></el-tab-pane>
          <el-tab-pane v-for="item in tree" :key="item.id" :label="item.title" :name="'' + item.id">
            <el-tabs
              class="tab-b"
              v-if="item.children != undefined"
              v-model="activeNameB"
              @tab-click="handleClick"
            >
              <el-tab-pane
                v-for="i in item.children"
                :key="i.id"
                :label="i.title"
                :name="'' + i.id"
              >
              </el-tab-pane>
            </el-tabs>
          </el-tab-pane>
        </el-tabs> -->
        <div class="search-wrap">
          <el-input
            class="search"
            v-model="listQuery.title"
            placeholder="搜索产品"
            style="width:200px;max-width:100%;height: 40px;"
          />
          <el-button
            type="primary "
            style="border-radius: 0;vertical-align:bottom;height: 40px;width: 44px"
            @click="searchClick"
          >
            <img src="@/assets/imp_search.png" alt="" style="margin-left: -7px;margin-top: -2px;" />
          </el-button>
        </div>
      </div>
    </div>

    <div class="wrap m-t-30">
      <div class="text-center p-t-30" v-if="productListNew.length === 0">
        <img src="@/assets/imp_zanwu.png" alt="" />
        <p class="p-t-50 fz-28 color-6">暂无相关产品</p>
      </div>
      <el-row :gutter="18" v-else>
        <el-col :span="6" v-for="(item, index) in productListNew" :key="index">
          <router-link :to="'/product/' + item.id">
            <el-card :body-style="{ padding: '0px' }" class="m-t-18  card">
              <img :src="item.cover" class="image" />
              <div style="padding: 14px;">
                <p class="fz-16 text-center overflow" style="color:#314260">{{ item.title }}</p>
                <p class="fz-16 text-center text-over" style="color:#314260">{{ item.shop }}</p>
                <div class="bottom clearfix ">
                  <p class="p-t-10 fz-14 color-blue text-center">{{ item.price }}元</p>
                </div>
              </div>
            </el-card>
          </router-link>
        </el-col>
      </el-row>
      <pagination
        class="bc-grey text-center"
        layout="prev, pager, next"
        v-show="total > 0"
        :total="total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.per_page"
        @pagination="pageClick()"
      />
    </div>
  </div>
</template>

<script>
import { getTree, getList } from '@/api/product'
import Pagination from '@/components/Pagination' // secondary package based on el-pagination

export default {
  name: 'product',
  components: { Pagination },
  productListNew: [],

  data() {
    return {
      activeNameA: '',
      activeNameB: '',
      tree: [],
      productListNew: [],

      listQuery: {
        title: '',
        cate_id: '',
        page: 1,
        per_page: 16
      },
      total: 0
    }
  },
  created: function() {
    this.getTree()
    this.getList(this.listQuery)
  },
  mounted: function() {
    // this.getTree()
    // this.getList(this.listQuery)
  },
  methods: {
    /**
     * @method getTree
     * @return {type} argName - description
     * @description: 获取分类联动菜单
     */
    getTree() {
      getTree().then(res => {
        this.tree = res.data
      })
    },
    /**
     * @method getList
     * @param {type} data - description
     * @return {type} argName - description
     * @description: 获取产品列表
     */
    getList(data) {
      // if(this.listQuery.id){
      //     this.listQuery.id= this.listQuery.id.match()
      // }
      getList(data).then(res => {
        this.productListNew = res.data.list
        this.total = res.data.count
      })
    },
    handleClick(tab) {
      let query = {}
      this.listQuery.page = 1
      if (tab.name) {
        let isType = this.tree.find(item => {
          return item.id == tab.name
        })
        if (isType) {
          query.type = tab.name
          this.activeNameB = ''
        } else {
          query.type = this.$route.query.type
          query.id = tab.name
        }
      }
      // query.title=this.listQuery.title

      query.page = this.listQuery.page
      this.$router.push({ path: '/product', query })
    },
    pageClick() {
      let query = {}
      if (this.$route.query.type) {
        query.type = this.$route.query.type
      }

      if (this.$route.query.id) {
        query.id = this.$route.query.id
      }

      query.page = this.listQuery.page
      query.title = this.listQuery.title
      this.$router.push({ path: '/product', query })
    },
    searchClick() {
      let query = {}
      query.title = this.listQuery.title
      if (this.listQuery.title) {
        this.$router.push({ path: '/product', query })
      }
    }
  },
  watch: {
    activeNameA(val) {
      this.listQuery.cate_id = val
    },
    activeNameB(val) {
      this.listQuery.cate_id = val
    },
    $route: {
      handler: function(route) {
        let data = {}
        if (route.query.id) {
          this.activeNameA = route.query.type
          this.activeNameB = route.query.id
          data.cate_id = route.query.id
        } else if (route.query.type) {
          this.activeNameA = route.query.type
          data.cate_id = route.query.type
        } else {
          this.activeNameA = ''
          this.activeNameB = ''
        }
        if (route.query.page) {
          this.listQuery.page = parseInt(route.query.page)
        }
        if (route.query.title) {
          this.listQuery.title = route.query.title
        } else {
          this.listQuery.title = ''
        }

        data.title = route.query.title
        data.page = route.query.page
        data.per_page = this.listQuery.per_page
        this.getList(data)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="less">
.nav-wrap {
  position: relative;
  .search-wrap {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    &/deep/input {
      border-radius: 0 !important;
      height: 40px;
    }
  }
}
.card {
  height: 370px !important;
}
/*.el-tabs__nav-scroll{*/
/*padding-left: 100px;*/
/*}*/
.nav {
  &/deep/.el-tabs__item {
    background-color: #f4f4f4;
    margin-right: 34px;
    color: #314260;
    padding: 0 20px !important;

    &.is-active {
      background-color: #0084ca;
      color: white;
    }
  }
  &/deep/.el-tabs__nav {
    padding-bottom: 12px;
  }
  &/deep/.el-tabs__active-bar {
    width: 0 !important;
  }
  &/deep/#tab-0 {
    background-color: white;
    font-size: 16px;
    color: #a6adb8;
  }
}
.tab-b/deep/.el-tabs__nav {
  padding-left: 142px;
  padding-bottom: 10px;
}
.tab-b/deep/.el-tabs__nav-wrap::after {
  height: 0;
}
.el-card {
  height: 424px;
  text-align: center;
  img {
    height: 245px;
    width: 245px;
  }
  p {
    line-height: 1.6;
  }
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /*规定最多显示两行*/
}
</style>
